export const PL_PDA_SUBMIT_START = 'PL_PDA_SUBMIT_START';
export const PL_PDA_SAVE_START = 'PL_PDA_SAVE_START';

export const PL_PDA_SUBMIT_SUCCESS = 'PL_PDA_SUBMIT_SUCCESS';
export const PL_PDA_SAVE_SUCCESS = 'PL_PDA_SAVE_SUCCESS';

export const PL_PDA_SUBMIT_FAILURE = 'PL_PDA_SUBMIT_FAILURE';
export const PL_PDA_SUBMIT_RESET = 'PL_PDA_SUBMIT_RESET';
export const PL_PDA_SUBMIT_VALIDATION_SET_ERROR = 'PL_PDA_SUBMIT_VALIDATION_SET_ERROR';
export const PL_PDA_SUBMIT_POPUP_CLOSE = 'PL_PDA_SUBMIT_POPUP_CLOSE';
